function init() {
    'use strict';
    //
}

document.addEventListener('DOMContentLoaded', init);
(function ($) {
    $(document).ready(function () {

        $('.close-pop-up').click(function () {
            $('.box-pop-up').removeClass('box-pop-up-open');
        })

        $('.box-with-pop-up').click(function () {
            if ($(this).hasClass('box-0')) {
                openPopUp('0', ($(this).closest('.pop-up-block')));
            } else if ($(this).hasClass('box-1')) {
                openPopUp('1', $(this).closest('.pop-up-block'));
            } else if ($(this).hasClass('box-2')) {
                openPopUp('2', $(this).closest('.pop-up-block'));
            } else {
                openPopUp('3', $(this).closest('.pop-up-block'));
            }
        });

        function openPopUp($popup, $parent) {
            $currentPopUp = $parent.children('.pop-up-' + $popup);
            $currentPopUp.addClass('box-pop-up-open');
            $otherPopUps = $parent.children('.box-pop-up');
            $otherPopUps.not($currentPopUp).removeClass('box-pop-up-open');
        }

    });
})(jQuery);
